import React from 'react'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>

        <SEO title="Fréttir og tilkynningar"
                description="" 
                pathname="/blog/"
            />
         <Hero 
         headerText="Fréttir og tilkynningar" 
         subHeaderText="Hér söfnum við saman áhugaverðum fréttum og tilkynningum"
         heroBanner="https://images.squarespace-cdn.com/content/v1/52a74d9ae4b0253945d2aee9/1390513380961-PTHFXE5U2S1FJSLPXUSD/ke17ZwdGBToddI8pDm48kA2MStDQoZ8TsVhOdZz9ancUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKc02ZN02e9uXhS_VCIvN0BPHpXNLjw_J6OzsHaJFwWx58AMuMC3AT9ww3KmldDNe7v/tumblr_mh1iruZWLf1rkz363o1_1280.jpg?format=1500w" /> 

        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
